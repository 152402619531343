<template>
  <div class="bg-gray-50 h-screen">
    <div class="p-4 pt-12 container max-w-screen-lg mx-auto">
      <!-- Staf List -->
      <section>
        <div class="mb-10 mt-10 grid grid-cols-2">
          <span class="text-xl mb-6 uppercase tracking-wide"> staf </span>
          <div class="text-right pr-3">
            <!-- trigger add new period -->
            <button class="button --primary ml-4" @click="SHOW_FORM()">
              <fa-icon icon="plus"></fa-icon>
              <span class="ml-2">staf</span>
            </button>
          </div>
        </div>

        <p v-if="!hasStaff" class="p-2 uppercase tracking-wide text-center">
          Belum ada staf
        </p>
        <div class="sm:grid sm:gap-4 sm:grid-cols-2 lg:grid-cols-3">
          <item-card
            class="mb-4 max-w-xs mx-auto bg-white"
            :is-active="true"
            v-for="(item, key) in staffs"
            :key="key"
          >
            <!-- Menu -->
            <template #menu>
              <button class="button" @click="showForm(item)">
                <span>Edit</span>
                <fa-icon icon="pencil-alt"></fa-icon>
              </button>
              <button class="button" @click="triggerDeleteStaff(item)">
                <span>Hapus</span>
                <fa-icon icon="trash-alt"></fa-icon>
              </button>
            </template>

            <!-- Contents -->
            <section class="grid grid-cols-5 gap-4 mt-4">
              <div class="col-span-3">
                <div class="mb-4">
                  <span class="subtle-label">nama</span>
                  <span class="content">{{ item.name }}</span>
                </div>
                <div class="">
                  <span class="subtle-label">jabatan</span>
                  <span class="content">{{ item.jobdesk }}</span>
                </div>
              </div>
              <div class="col-span-2">
                <img
                  :src="item.thumbnail_url_full"
                  alt="Belum ada foto"
                  :id="`staff-image-${item.id}`"
                  class="shadow rounded w-32"
                  :class="{ 'p-2': item.thumbnail_url_full === null }"
                />
              </div>
              <div class="col-span-5">
                <span class="subtle-label">biografi singkat</span>
                <span class="content">{{ item.description }}</span>
              </div>
            </section>
          </item-card>
        </div>
      </section>

      <!-- Staff Form -->
      <item-form :is-visible="form_visibility">
        <template #hide-trigger>
          <button
            class="p-2 px-4 bg-yellow-400 form__trigger-hide"
            @click="hideForm()"
          >
            <fa-icon icon="times"></fa-icon>
          </button>
        </template>

        <template #default>
          <p class="mb-6">
            <span class="text-xl uppercase tracking-wider">formulir staf</span>
          </p>
          <div v-if="form.id !== null" class="mt-4 mb-8">
            <img
              :src="form.thumbnail_url_full"
              alt="Belum ada foto"
              id="form-image"
              style="min-height: 150px"
              class="border border-gray-200 mx-auto text-gray-500 text-center"
            />
            <image-uploader
              ref="imageUploader"
              text="Unggah"
              :ratio="1"
              @ready="triggerUploadImage"
            ></image-uploader>
          </div>

          <form @submit.prevent="submit()" class="grid grid-cols-1 gap-2">
            <!-- staff name -->
            <div class="">
              <label for="staff-name">Nama</label>
              <input type="text" id="period-name" v-model="form.name" />
            </div>

            <!-- staff job desk -->
            <div class="">
              <label for="staff-jobdesk">Bidang</label>
              <input type="text" id="staff-jobdesk" v-model="form.jobdesk" />
            </div>

            <!-- staff biography -->
            <div class="">
              <label for="staff-biography">Biografi Singkat</label>
              <textarea
                type="text"
                id="staff-biography"
                v-model="form.description"
              >
              </textarea>
            </div>

            <div class="">
              <button class="button my-6 w-full --primary">
                <fa-icon icon="check"></fa-icon>
                <span class="ml-2">Simpan</span>
              </button>
            </div>
          </form>
        </template>
      </item-form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.staff-profile-container {
  @apply p-4 shadow mb-8 rounded bg-gray-100 mt-36;
  @apply max-w-sm;
}
</style>

<script>
import _clone from "lodash/clone";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      form: {
        id: null,
        name: null,
        jobdesk: null,
        description: null,
      },
      formDefault: {},
    };
  },
  methods: {
    async submit() {
      if (this.form.id === null) {
        await this.storeStaff(this.form);
      } else {
        await this.updateStaff(this.form);
      }
      this.hideForm();
    },

    async triggerDeleteStaff(staff) {
      await this.deleteStaff(staff);
    },

    async triggerUploadImage(file) {
      const formData = new FormData();
      formData.append("image", file);

      await this.updateStaffPhoto({
        item: this.form,
        data: formData,
      });

      // reload image
      let staffId = this.form.id;

      const reader = new FileReader();
      reader.onload = function (e) {
        let imageContainer = document.getElementById(`staff-image-${staffId}`);
        imageContainer.src = e.target.result;

        let formImageContainer = document.getElementById("form-image");
        formImageContainer.src = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    showForm(staff) {
      this.SHOW_FORM();
      this.SET_SELECTED_STAFF(staff);
      this.form = _clone(this.selected_staff);
      this.hideMenu();
    },

    hideForm() {
      this.HIDE_FORM();
      this.UNSET_SELECTED_STAFF();

      if (this.form.id !== null) {
        // clear image preview on form
        let formImageContainer = document.getElementById("form-image");
        formImageContainer.src = null;
      }

      this.form = _clone(this.formDefault);
    },

    hideMenu() {
      EventBus.$emit("item-card.menu.hide");
    },

    ...mapActions("management_staff", [
      "storeStaff",
      "fetchStaff",
      "updateStaff",
      "deleteStaff",
      "updateStaffPhoto",
    ]),

    ...mapMutations("management_staff", [
      "SHOW_FORM",
      "HIDE_FORM",
      "SET_SELECTED_STAFF",
      "UNSET_SELECTED_STAFF",
    ]),
  },
  computed: {
    hasStaff() {
      return this.staffs.length > 0;
    },

    ...mapGetters("management_staff", [
      "form_visibility",
      "staffs",
      "selected_staff",
    ]),
  },
  mounted() {
    this.fetchStaff();
    this.formDefault = _clone(this.form);
  },
  components: {
    ItemForm: () => import("@/components/Common/ItemForm.vue"),
    ItemCard: () => import("@/components/Common/ItemCard.vue"),
    ImageUploader: () => import("@/components/Common/ImageUploader.vue"),
  },
  name: "ManagementStaff",
};
</script>